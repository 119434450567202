import Typography from '@mui/material/Typography';
import {Breadcrumbs, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Link} from '@mui/material';
import {useEffect, useState} from "react";
import DashboardTitle from "../../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import {BaseCard} from "../../../components/BaseCard";
import {useOperation} from "../../../contexts/OperationContext";
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import Box from "@mui/material/Box";
import {AnnotatedValueCalculationInput, FinancialDataTS} from "../../../types/financialData/PLData";
import PLTable from "../../../components/financialData/PLTable";
import Button from "@mui/material/Button";
import formatCurrencyWithParens from "../../../components/formatting/formatCurrencyWithParens";
import {formatNumberAsPercent} from "../../../utils/formatting";

const CALCULATION_INPUT_RATING_TO_COLOR = {
    "HIGH": "error",
    "MEDIUM": "warning",
    "LOW": "success",
}

type DetailModalInfoType = {
    category: string
    year: string
    explanation: string
    value: number
    previousValue: number | null
    calculationInputs?: AnnotatedValueCalculationInput[]
}

// TODO: Fix this data to pull from API and convert to numbers
const financialSummaryData = [
    {
        year: '2024',
        projectedBaseline: -13790.88,
        projectedProfitLow: -12670,
        projectedProfitMid: -12170.88,
        projectedProfitHigh: -11230,
        projectedProfitRange: [-12670, -11230]
    },
    {
        year: '2025',
        projectedBaseline: -13107.20,
        projectedProfitLow: 15,
        projectedProfitMid: 401,
        projectedProfitHigh: 813,
        projectedProfitRange: [15, 813]
    },
    {
        year: '2026',
        projectedBaseline: -13107.20,
        projectedProfitLow: 21626.63,
        projectedProfitMid: 23765.53,
        projectedProfitHigh: 26142.08,
        projectedProfitRange: [21626.63, 26142.08]
    },
    {
        year: '2027',
        projectedBaseline: -13107.20,
        projectedProfitLow: 45876.38,
        projectedProfitMid: 50413.6,
        projectedProfitHigh: 55454.96,
        projectedProfitRange: [45876.38, 55454.96]
    },
    {
        year: '2028',
        projectedBaseline: -13107.20,
        projectedProfitLow: 76350.09,
        projectedProfitMid: 83901.2,
        projectedProfitHigh: 92291.32,
        projectedProfitRange: [76350.09, 92291.32]
    },
    {
        year: '2029',
        projectedBaseline: -13107.20,
        projectedProfitLow: 121315.558,
        projectedProfitMid: 133313.8,
        projectedProfitHigh: 146645.18,
        projectedProfitRange: [121315.558, 146645.18]
    },
]

const financialData: FinancialDataTS = {
    "year": ['2024', "2025", "2026", "2027", "2028", "2029"],
    "net_income": [-12170.88, 401.08, 23765.53, 50413.6, 83901.2, 133313.8],
    "costs": {
        "totals": [-20715.48, {
            value: -26218.92,
            annotation: "Introducing daily moves for full year increases labor costs"
        }, -26218.22, -29564.4, -34827.3, -40090.2],
        "categories": [
            {
                "name": "Per Cow",
                "totals": [-10365.48, -10688.92, -10688.22, -14034.4, -19297.3, -24560.2],
                "subcategories": [
                    {
                        "name": "Harvested Forage (Hay)",
                        "totals": [
                            {
                                value: -4860,
                                annotation: "Currently feeding 4T/cow, which can be reduced to 0.5 (as backup)",
                                type: "warning"
                            },
                            {
                                value: -3960,
                                annotation: "Extending winter grazing season cutes hay requirements per cow by 25%"
                            },
                            {
                                value: -2430,
                                annotation: "Further extending winter grazing season cutes hay requirements from 3 to 2 tons/cow"
                            },
                            -1800,
                            {
                                value: -2475,
                                annotation: "Hay per cow will not decrease further due to need to stockpile in case of harsh winter. Cost increase comes from increasing herd size."
                            },
                            -3150
                        ]
                    },
                    {
                        "name": "Grain/Protein Supplements",
                        "totals": [0, 0, 0, 0, 0, 0]
                    },
                    {
                        "name": "Mineral",
                        "totals": [-102.6, -125.4, -153.9, -228, -313.5, -399]
                    }, {
                        "name": "Other Feed",
                        "totals": [0, 0, 0, 0, 0, 0]
                    }, {
                        "name": "Replacement Females",
                        "totals": [-5402.88, -6603.52, -8104.32, -12006.4, -16508.8, -21011.2]
                    }
                ]
            },
            {
                "name": "Per Operation / Herd",
                "totals": [-7200, -12380, -12380, -12380, -12380, -12380],
                "subcategories": [
                    {
                        "name": "Annual Bull Charge or A.I.",
                        "totals": [-3000, -3000, -3000, -3000, -3000, -3000]
                    },
                    {
                        "name": "Other Livestock Breeding/Marketing",
                        "totals": [0, 0, 0, 0, 0, 0]
                    },
                    {
                        "name": "Utilities",
                        "totals": [-300, -300, -300, -300, -300, -300]
                    },
                    {
                        "name": "Gas, Fuel, Oil",
                        "totals": [-300, -300, -300, -300, -300, -300]
                    },
                    {
                        "name": "Machinery, Facility/Equipment Repairs",
                        "totals": [-900, -900, -900, -900, -900, -900]
                    },
                    {
                        "name": "Cash Interest Paid",
                        "totals": [-400, -400, -400, -400, -400, -400]
                    },
                    {
                        "name": "Other variable costs",
                        "totals": [-200, -200, -200, -200, -200, -200]
                    },
                    {
                        "name": "Labor (Hired & Operator)",
                        "totals": [
                            -2100,
                            {
                                value: -7280,
                                annotation: "Increase in labor costs due to need for daily moves"
                            }, {

                                value: -7280,
                                annotation: "No further increase in costs as herd size grows since time taken to do move is independent of herd size"
                            },
                            -7280,
                            -7280,
                            -7280
                        ]
                    },
                ]
            },
            {
                "name": "Per Acre",
                "totals": [-3150, -3150, -3150, -3150, -3150, -3150],
                "subcategories": [
                    {
                        "name": "Pasture Seeding",
                        "totals": [-3150, -3150, -3150, -3150, -3150, -3150]
                    },
                ]
            }
        ]
    },
    "revenues": {
        "totals": [
            {
                value: 8544.6,
                annotation: "Limited total revenues as only carrying 18 cows. This can be increased to at least 80.",
                type: "warning"
            },
            26620,
            49983.75,
            79978,
            118728.5,
            173404],
        "categories": [
            {
                "name": "Animal Sales",
                "totals": [8544.6, 26620, 49983.75, 79978, 118728.5, 173404],
                "subcategories": [
                    {
                        "name": "Weaned Calf Sales",
                        "totals": [
                            {
                                value: 5232.6,
                                annotation: "Weaning rates <20% - this is significantly limiting revenue potential on current stocking rate.",
                                type: "warning",
                                calculation: {
                                    type: "multiplication",
                                    inputs: [
                                        {
                                            value: 2.85,
                                            format: formatCurrencyWithParens,
                                            unit: "price per lb",
                                            annotation: "Determined by market,can get more from premium markets, but not an immediate priority",
                                            rating: "LOW"
                                        },
                                        {
                                            value: 600,
                                            format: (value: number) => `${value}`,
                                            unit: "lbs / calf",
                                            annotation: "Slight opportunity to increase to 700lbs, but not an immediate priority",
                                            rating: "LOW"
                                        },
                                        {
                                            value: 0.17,
                                            format: formatNumberAsPercent,
                                            unit: "calves / cow",
                                            annotation: "This is a very low number and should be a major priority to increase revenues",
                                            rating: "HIGH"
                                        },
                                        {
                                            value: 18,
                                            format: (value: number) => `${value}`,
                                            unit: "cows in herd",
                                            annotation: "This is a low number against potential (but in line with county averages) and can be increased over long term via pasture improvements",
                                            rating: "MEDIUM"
                                        },
                                    ]
                                }
                            },
                            {
                                value: 22572,
                                annotation: "Increase weaning rates to 60% by addressing fescue infection by planting diverse legumes and fobs",
                                calculation: {
                                    type: "multiplication",
                                    inputs: [
                                        {
                                            value: 2.85,
                                            format: formatCurrencyWithParens,
                                            unit: "price per lb",
                                            annotation: "Determined by market,can get more from premium markets, but not an immediate priority",
                                            rating: "LOW"
                                        },
                                        {
                                            value: 600,
                                            format: (value: number) => `${value}`,
                                            unit: "lbs / calf",
                                            annotation: "Slight opportunity to increase to 700lbs, but not an immediate priority",
                                            rating: "LOW"
                                        },
                                        {
                                            value: 0.6,
                                            format: formatNumberAsPercent,
                                            unit: "calves / cow",
                                            annotation: "Adding legumes to infested fescue will help increase calving rate significantly, though there is still room for improvement",
                                            rating: "MEDIUM"
                                        },
                                        {
                                            value: 22,
                                            format: (value: number) => `${value}`,
                                            unit: "cows in herd",
                                            annotation: "This number will slowly increase as we improve pasture quality and increase herd size",
                                            rating: "MEDIUM"
                                        },
                                    ]
                                }
                            },
                            {
                                value: 45015,
                                annotation: "Increased herd size, and therefore calves for sale, due to more productive pasture. Main gains this year from more productive winter and summer auunals"
                            },
                            {
                                value: 72618,
                                annotation: "Increasing weaning rates by addressing fescue infection increases sales revenue significantly"
                            },
                            {
                                value: 108608,
                                annotation: "Increasing weaning rates by addressing fescue infection increases sales revenue significantly"
                            },
                            {
                                value: 160524,
                                annotation: "Close to maximum carrying capacity of pasture and weaning weight for calves. Also selling into premium market",
                                calculation: {
                                    type: "multiplication",
                                    inputs: [
                                        {
                                            value: 3.60,
                                            format: formatCurrencyWithParens,
                                            unit: "price per lb",
                                            annotation: "Increased value selling to premium, organic markets",
                                            rating: "LOW"
                                        },
                                        {
                                            value: 700,
                                            format: (value: number) => `${value}`,
                                            unit: "lbs / calf",
                                            annotation: "Higher quality pasture increases sale weight",
                                            rating: "LOW"
                                        },
                                        {
                                            value: 0.91,
                                            format: formatNumberAsPercent,
                                            unit: "calves / cow",
                                            annotation: "Full weaning potential around 91%",
                                            rating: "LOW"
                                        },
                                        {
                                            value: 70,
                                            format: (value: number) => `${value}`,
                                            unit: "cows in herd",
                                            annotation: "Increased pasture productivity and annual grazing increases carrying capacity per acre",
                                            rating: "LOW"
                                        },
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "name": "Cull Cows Sales",
                        "totals": [
                            3312,
                            {
                                value: 4048,
                                annotation: "Increase in cull sale revenue from increased herd size while cull rate remains the same."
                            },
                            4968,
                            7360,
                            10120,
                            12880]
                    },
                ]
            }
        ]
    }
}


const PlanningFinances = () => {
    const {operation} = useOperation()

    const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
    const [detailModalInfo, setDetailModalInfo] = useState<DetailModalInfoType | null>(null)

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false)
    }

    const valueCellCallback = (category: string, year: string, explanation: string, value: number, previousValue: number | null, calculationInputs: AnnotatedValueCalculationInput[]) => {
        setDetailModalInfo({
            category: category,
            year: year,
            explanation: explanation,
            value: value,
            previousValue: previousValue,
            calculationInputs: calculationInputs,
        })
        setDetailModalOpen(true)
    }

    const NumberDetailsModal = () => {
        if (detailModalInfo !== null) {

            const ValueEl = () => {
                if (detailModalInfo.previousValue !== null) {
                    return (<DialogContentText>
                        <Typography variant={"h5"}
                                    component={"span"}>{formatCurrencyWithParens(detailModalInfo.previousValue)} </Typography>
                        to
                        <Typography variant={"h4"}
                                    component={"span"}> {formatCurrencyWithParens(detailModalInfo.value)}</Typography>
                    </DialogContentText>)
                } else {
                    return <DialogContentText>

                        <Typography variant={"h4"}
                                    component={"span"}>{detailModalInfo.calculationInputs ? "=" : ""} {formatCurrencyWithParens(detailModalInfo.value)}</Typography>
                    </DialogContentText>
                }
            }

            const CalculationEl = () => {
                if (detailModalInfo.calculationInputs) {
                    return <>
                        {
                            detailModalInfo.calculationInputs.map((input, index) => {
                                if (input.annotation) {
                                    const color = CALCULATION_INPUT_RATING_TO_COLOR[input.rating]
                                    return <Box>
                                        {/* TODO: DEMO: Reenable tooltip */}
                                        {/*<MuiTooltop title={*/}
                                        {/*    <Typography>{input.annotation}</Typography>*/}
                                        {/*} arrow>*/}
                                            <Typography variant={"h6"} component={"span"} sx={{marginBottom: "4px"}} color={`${color}.main`}>{index > 0 ? "* " : ""} {input.format(input.value)} {input.unit}</Typography>
                                        {/*</MuiTooltop>*/}
                                    </Box>
                                } else {
                                    return <Box><Typography variant={"h5"} component={"span"}>{index > 0 ? "* " : ""} {input.format(input.value)} {input.unit}</Typography></Box>
                                }
                            })
                        }
                    </>
                } else {
                    return <></>
                }
            }

            return <Dialog
                open={detailModalOpen && detailModalInfo !== null}
                onClose={handleCloseDetailModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <DialogTitle id="modal-modal-title">
                        <Typography variant={"h3"}>
                            {`${detailModalInfo.year}: ${detailModalInfo.category}`}
                        </Typography>
                    </DialogTitle>
                    <DialogContent id="modal-modal-description">
                        <CalculationEl/>
                        <ValueEl/>
                        <DialogContentText sx={{marginTop: '16px'}}>
                            {detailModalInfo.explanation}
                        </DialogContentText>
                    </DialogContent>
                </Box>
            </Dialog>
        } else {
            return <></>
        }
    }

    useEffect(() => {
        if (operation) {
            // const params = {operation_id: operation.id}
            // axios.get('/planning/finances', {params})
            //     .then(response => {
            //     })
        }
    }, [operation]);

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.PLANNING.INDEX}>
                            Planning
                        </Link>
                        <Typography color="textPrimary">
                            Finances
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Finances"}/>
                <Grid item width={'100%'}>
                    <BaseCard title={"Financial Projections"}>
                        <Box style={{width: '100%', height: '500px'}}>
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    width={800}
                                    height={4}
                                    data={financialSummaryData}
                                    margin={{
                                        top: 20,
                                        right: 40,
                                        left: 60,
                                        bottom: 90,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>

                                    <XAxis dataKey="year">
                                        <Label
                                            value="Year"
                                            position="bottom"
                                            offset={60}
                                            style={{textAnchor: 'middle'}}
                                        />
                                    </XAxis>
                                    <YAxis yAxisId="left">
                                        <Label
                                            value="Net Income ($)"
                                            position="insideLeft"
                                            offset={-20}
                                            angle={-90}
                                            style={{textAnchor: 'middle'}}
                                        />
                                    </YAxis>
                                    <Legend verticalAlign="top" height={36}/>
                                    <Line dataKey="projectedBaseline" yAxisId="left" stroke="#ef9c82"/>
                                    <Line dataKey="projectedProfitLow" yAxisId="left" stroke="#3da866"/>
                                    <Line dataKey="projectedProfitMid" yAxisId="left" stroke="#3da866"/>
                                    <Line dataKey="projectedProfitHigh" yAxisId="left" stroke="#3da866"/>
                                    <Area dataKey="projectedProfitRange" stroke="#3da866" fill="#3da866"
                                          fillOpacity={0.1} yAxisId="left"/>
                                    <ReferenceLine y={0} stroke="red" strokeDasharray="3 3"
                                                   yAxisId="left" isFront={false}/>
                                    <Tooltip/>
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Box>
                    </BaseCard>
                </Grid>
                <Grid item width={"100%"}>
                    <BaseCard title={"Profit and Loss Projections"}>
                        <PLTable financialData={financialData} valueCellCallback={valueCellCallback}/>
                    </BaseCard>
                </Grid>
            </Grid>
            <Button onClick={() => setDetailModalOpen(true)}>Test</Button>
            <NumberDetailsModal/>
        </>
    )
}

export default PlanningFinances