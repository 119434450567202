import Map from '../../components/Map';
import Typography from '@mui/material/Typography';
import {Card, CircularProgress, Grid} from '@mui/material';
import {FC, useEffect, useState} from "react";
import axios from "../../services/APIService";
import DashboardTitle from "../../components/DashboardTitle";
import Markdown from 'react-markdown';
import {TextBaseCard} from "../../components/BaseCard";
import {LatLngCoordinatePathType, LatLngCoordinateType, PolygonData, RawCoordinatePathType} from "../../types/geo";
import {rawTolatLngCoord, rawToLatLngPath} from "../../utils/geo";
import {useOperation} from "../../contexts/OperationContext";

interface ContextReportDataType {
    report_content: string
    report_summary: string
}

const ContextReport: FC = () => {
    const [report, setReport] = useState<ContextReportDataType | null>(null)
    const [parcelBoundaryPaths, setParcelBoundaryPaths] = useState<LatLngCoordinatePathType[] | null>(null)
    const [parcelCentroid, setParcelCentroid] = useState<LatLngCoordinateType | null>(null)
    const [boundaryPolygons, setBoundaryPolygons] = useState<PolygonData[]>([])
    const {operation} = useOperation()


    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/context/context-report/', {params})
                .then(reportInfo => {
                    setReport(reportInfo.data);
                })

            axios.get('/accounts/operation-coords/', {params})
                .then(coordsInfo => {
                    setParcelBoundaryPaths(coordsInfo.data.boundaries.map((boundary: RawCoordinatePathType[]) => {
                        return rawToLatLngPath(boundary[0])
                    }))
                    setParcelCentroid(rawTolatLngCoord(coordsInfo.data.centroid))
                })
        }
    }, [operation])

     useEffect(() => {
        if (parcelBoundaryPaths && parcelBoundaryPaths.length > 0) {
            setBoundaryPolygons(parcelBoundaryPaths.map((parcelBoundaryPath) => {
                return {
                    paths: parcelBoundaryPath,
                    strokeColor: '#0000FF',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#000000',
                    fillOpacity: 0.01,
                }
            }))
        }
    }, [parcelBoundaryPaths]);

    const ReportCards: FC<{ reportContent: string, reportSummary: string }> = ({reportContent, reportSummary}) => {
        return <>
            {/*Split the report into sections based on titles, removing the initial item from split as that is an empty string*/}
            {/*    // reportRaw.split("###").slice(1).map((inner) => {*/}
            {/*    //     const title = inner.split("\n")[0];*/}
            {/*    //     const contents = inner.split(title)[1]*/}
            {/*    //*/}
            {/*    // return <>*/}
            <TextBaseCard title={"Summary"}>
                <Markdown>{reportSummary}</Markdown>
            </TextBaseCard>
            <TextBaseCard title={"Details"}>
                <Markdown>{reportContent}</Markdown>
            </TextBaseCard>
        </>
    }


    return <>
        <Grid container spacing={2.5}>
            <DashboardTitle title={"Overview"}/>
            <Grid item width={'100%'}>
                <Card
                    sx={{
                        width: '100%',
                        padding: '24px',
                        background: '#FFFFFF'
                    }}
                >
                    {(boundaryPolygons && parcelCentroid)
                        ?
                        <Map key={operation ? operation.id : undefined} polygons={boundaryPolygons}
                             centroid={parcelCentroid}></Map>
                        :
                        <></>
                    }
                </Card>
            </Grid>
            {
                (!report)
                    ?
                    <>
                        <CircularProgress/>
                        <Typography variant={'h2'}>Loading Context Report</Typography>
                    </>
                    :
                    <>
                        <ReportCards reportContent={report.report_content} reportSummary={report.report_summary}/>
                    </>
            }
        </Grid>
    </>
};

export default ContextReport;