import Map from '../../components/Map';
import {Breadcrumbs, Card, CardActions, CardContent, Chip, Grid, Link} from '@mui/material';
import {FC, useEffect, useState} from "react";
import axios from "../../services/APIService";
import {LatLngCoordinatePathType, LatLngCoordinateType, PolygonData, RawCoordinatePathType} from "../../types/geo";
import {rawTolatLngCoord, rawToLatLngPath} from "../../utils/geo";
import {useOperation} from "../../contexts/OperationContext";
import {SubDivision} from "../../types/planning/geographical";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {TextBaseCard} from "../../components/BaseCard";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";

const CalvingRateChip = () => {
    return <Chip size={"small"} sx={{backgroundColor: "#f7e199", marginRight: "4px", marginBottom: "4px"}}
                 label="Calving Rates"/>
}

const HayCostChip = () => {
    return <Chip size={"small"} sx={{backgroundColor: "#baedad", marginRight: "4px", marginBottom: "4px"}}
                 label="Hay Costs"/>
}

const PastureProductivityChip = () => {
    return <Chip size={"small"} sx={{backgroundColor: "#b9cff0", marginRight: "4px", marginBottom: "4px"}}
                 label="Pasture Productivity"/>
}

const opportunities = [
    {
        headline: "Increase revenue per cow",
        metric: "2x revenue per head",
        content: "Revenues will increase as calving rates and sale weights increase. These will be enabled through high quality, year-round forage that is clear of endophyte infected fescue",
        chips: [<CalvingRateChip/>]
    },
    {
        headline: "Decrease hay costs per animal",
        metric: "4 to 0.5 tons fed per head",
        content: "Year-round grazing will eliminate hay costs most years if diverse pasture types are planted, including native grasses, cool season grasses, and winter and summer annuals.",
        chips: [<HayCostChip/>]
    },
    {
        headline: "Increase carrying capacity",
        metric: "3-4x head per acre",
        content: "The carrying capacity of this operation can be significantly increased by enabling year-round grazing and increasing the quality and quantity of pasture by increasing nutrient content and microbial activity.",
        chips: [<PastureProductivityChip/>]
    },
]

const solutions = [
    {
        headline: "Regular cattle moves",
        content: "",
        chips: [<PastureProductivityChip/>, <HayCostChip/>]
    },
    {
        headline: "Increase carrying capacity",
        metric: "3-4x head per acre",
        content: "",
        chips: [<PastureProductivityChip/>]
    },
    {
        headline: "Increase carrying capacity",
        metric: "3-4x head per acre",
        content: "",
        chips: [<PastureProductivityChip/>]
    },
    {
        headline: "Increase carrying capacity",
        metric: "3-4x head per acre",
        content: "",
        chips: [<PastureProductivityChip/>]
    },
    {
        headline: "Increase carrying capacity",
        metric: "3-4x head per acre",
        content: "",
        chips: [<PastureProductivityChip/>]
    },

]


const OpportunityOverview: FC = () => {
    const [parcelBoundaryPaths, setParcelBoundaryPaths] = useState<LatLngCoordinatePathType[] | null>(null)
    const [subdivisions, setSubdivisions] = useState<SubDivision[]>([])
    const [subdivisionBoundaryPaths, setSubdivisionBoundaryPaths] = useState<LatLngCoordinatePathType[]>([])
    const [parcelCentroid, setParcelCentroid] = useState<LatLngCoordinateType | null>(null)
    const [boundaryPolygons, setBoundaryPolygons] = useState<PolygonData[]>([])
    const [hoveredSubdivisionId, setHoveredSubdivisionId] = useState<string | null>(null)
    const [openedSubdivisionId, setOpenedSubdivisionId] = useState<string | null>(null)
    const {operation} = useOperation()


    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}

            axios.get('/accounts/operation-coords/', {params})
                .then(coordsInfo => {
                    setSubdivisions(coordsInfo.data.subdivisions)
                    setParcelBoundaryPaths(coordsInfo.data.boundaries.map((boundary: RawCoordinatePathType[]) => {
                        return rawToLatLngPath(boundary[0])
                    }))
                    setParcelCentroid(rawTolatLngCoord(coordsInfo.data.centroid))
                    setSubdivisionBoundaryPaths(coordsInfo.data.subdivisions.map((subdivision: SubDivision) => {
                        return rawToLatLngPath(subdivision.boundary)
                    }))
                })

            // axios.get('/planning/subdivisions/', {params})
            //     .then(coordsInfo => {
            //         setParcelBoundaryPaths(coordsInfo.data.boundaries.map((boundary: RawCoordinatePathType[]) => {
            //             return rawToLatLngPath(boundary[0])
            //         }))
            //         setParcelCentroid(rawTolatLngCoord(coordsInfo.data.centroid))
            //     })
        }
    }, [operation])

    useEffect(() => {
        if (parcelBoundaryPaths && parcelBoundaryPaths.length > 0 && subdivisionBoundaryPaths && subdivisionBoundaryPaths.length > 0) {
            setBoundaryPolygons(
                [
                    ...parcelBoundaryPaths.map((parcelBoundaryPath) => {
                        return {
                            paths: parcelBoundaryPath,
                            strokeColor: '#0000FF',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#000000',
                            fillOpacity: 0.01,
                            // onClickFn: () => {alert('test')},
                        }
                    }),
                    ...subdivisions.map((subdivision) => {
                        return {
                            paths: rawToLatLngPath(subdivision.boundary),
                            strokeColor: '#00FF00',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#000000',
                            fillOpacity: 0.01,
                            onClickFn: () => setHoveredSubdivisionId(subdivision.identifier),
                            mouseoverFn: () => setHoveredSubdivisionId(subdivision.identifier),
                            mouseoutFn: () => setHoveredSubdivisionId(null),

                        }
                    })
                ]
            )
        }
    }, [parcelBoundaryPaths, subdivisionBoundaryPaths]);


    return <>
        <Grid container spacing={2.5}>
            <Grid item width={'100%'}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} to={Paths.PLANNING.INDEX}>
                        Planning
                    </Link>
                    <Typography color="textPrimary">
                        Overview
                    </Typography>
                </Breadcrumbs>
            </Grid>
            {/*<DashboardTitle title={"Opportunities"}/>*/}
            <Grid>
            </Grid>

            <Grid item width={'100%'}>
                <Typography>There is an opportunity to quadruple the operation's carrying capacity over time, increase sale weights, reduce feed costs, and extend the grazing season, making grass-finishing more viable. Enhanced soil fertility and drought resilience will further boost productivity and sustainability.</Typography>
            </Grid>

            <Grid item width={'100%'}>
                <Typography variant={'h4'} gutterBottom>Opportunities</Typography>
                <Grid container spacing={2}>
                    {opportunities.map((opportunity, index) => (
                        <Grid item xs={12} sm={6} md={4} sx={{display: 'flex'}} key={index}>
                            <TextBaseCard
                                sx={{
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    // height: '100%',  // Make the card fill the Grid item height
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                title={opportunity.headline}
                            >
                                <Typography gutterBottom variant="h6"
                                            color="textSecondary">{opportunity.metric}</Typography>
                                {opportunity.chips.map((chip) => chip)}
                                <Typography gutterBottom variant="body1">{opportunity.content}</Typography>
                            </TextBaseCard>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

             <Grid item width={'100%'}>
                <Typography variant={'h4'} gutterBottom>Solutions</Typography>
                <Grid container spacing={2}>
                    {solutions.map((solution, index) => (
                        <Grid item xs={12} sm={6} md={4} sx={{display: 'flex'}} key={index}>
                            <TextBaseCard
                                sx={{
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    height: '100%',  // Make the card fill the Grid item height
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                title={solution.headline}
                            >
                                {solution.chips.map((chip) => chip)}
                                <Typography gutterBottom variant="body1">{solution.content}</Typography>
                            </TextBaseCard>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item width={'100%'}>
                <Card
                    sx={{
                        width: '100%',
                        padding: '24px',
                        background: '#FFFFFF'
                    }}
                >
                    {(boundaryPolygons && parcelCentroid)
                        ?
                        <Map key={operation ? operation.id : undefined} polygons={boundaryPolygons}
                             centroid={parcelCentroid}></Map>
                        :
                        <></>
                    }
                </Card>
            </Grid>

            <Grid item width={'100%'}>
                {/*<Card title={"Summary"}>*/}

                {/*<CardContent>*/}
                This operation currently supports 18 head of cattle
                {/*</CardContent>*/}
                {/*</Card>*/}
            </Grid>

            {/* Section for subdivision cards */}
            <Grid item width={'100%'}>
                <Grid container spacing={2}>
                    {subdivisions.map((subdivision) => (
                        <Grid item xs={12} sm={6} md={4} key={subdivision.identifier}>
                            <Card
                                sx={{
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    transform: hoveredSubdivisionId === subdivision.identifier ? 'scale(1.02)' : 'scale(1)',
                                    boxShadow: hoveredSubdivisionId === subdivision.identifier ? 4 : 1,
                                    backgroundColor: hoveredSubdivisionId === subdivision.identifier ? '#f0f0f0' : 'inherit',
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6">{subdivision.name}</Typography>
                                    <Typography color="textSecondary">ID: {subdivision.identifier}</Typography>
                                    <Typography variant="body2">{subdivision.area} acres</Typography>
                                    {
                                        openedSubdivisionId === subdivision.identifier
                                            ?
                                            <Typography variant="body2">
                                                {"fvhid iuhfviudhfviu idfuhiuvdh iuhfd iuh iu hiudfhi uhd fiuh idfuh iuhdfiuhdif iuhdi fuh i  idufhiuhiuhi dfuhiudfhi uh iuh iudhfi uhi duh iufd"}
                                            </Typography>
                                            :
                                            <></>
                                    }
                                </CardContent>
                                <CardActions>
                                    {
                                        openedSubdivisionId === subdivision.identifier
                                            ?
                                            <Button size="small"
                                                    onClick={(e) => setOpenedSubdivisionId(null)}>Less</Button>
                                            :
                                            <Button size="small"
                                                    onClick={(e) => setOpenedSubdivisionId(subdivision.identifier)}>More</Button>
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </>
};

export default OpportunityOverview;